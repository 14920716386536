.border-1px(@color: #ccc, @radius: 2px, @style: solid) {
    position: relative;
    
    &:after {
        content: "";
        pointer-events: none; // 解决iphone上的点击无效Bug
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: 0 0;
        border: 1px @style @color;
        border-radius: @radius;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        
        @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 2) {
            width: 200%;
            height: 200%;
            border-radius: @radius * 2;
            transform: scale(.5) translateZ(0);
        }
        
        @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
            width: 300%;
            height: 300%;
            border-radius: @radius * 3;
            transform: scale(.33) translateZ(0);
        }
    }
}

.border-bottom(@size: 1px, @color: #ccc, @radius: 2px, @style: solid) {
    position: relative;
    
    &:after {
        content: "";
        pointer-events: none; // 解决iphone上的点击无效Bug
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: 0 0;
        border-bottom: @size @style @color;
        border-radius: @radius;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        
        @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 2) {
            width: 200%;
            height: 200%;
            border-radius: @radius * 2;
            transform: scale(.5) translateZ(0);
        }
        
        @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
            width: 300%;
            height: 300%;
            border-radius: @radius * 3;
            transform: scale(.33) translateZ(0);
        }
    }
}

.border-none {
    &:before {
        display: none;
    }
    
    &:after {
        display: none
    }
}

.flex-fix {
    -ms-flex: 1 1 0.000000001px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    -webkit-flex-basis: 0.000000001px;
    flex-basis: 0.000000001px;
    width: 1%;
}

.touch-active(@type: #108FF4) when (@type =#108FF4) {
    &:active {
        color: #fff;
        background-color: rgba(16, 143, 244, 0.7);
    }
}

.touch-active(@type) when not (@type =#108FF4) {
    &:active {
        opacity: .7;
    }
}


.hide-scrollbar {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}


// 背景图片地址和大小
.bis(@url) {
    background-image: ~"url('@{url}@2x.png')";
    background-repeat: no-repeat;
    background-size: 100% 100%;
    @media (-webkit-min-device-pixel-ratio: 3), (min-device-pixel-ratio: 3) {
        background-image: ~"url('@{url}@3x.png')";
    }
}

.borderRadius(@radius) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    -ms-border-radius: @radius;
    -o-border-radius: @radius;
    border-radius: @radius;
}

//定位全屏
.allcover {
    position: absolute;
    top: 0;
    right: 0;
}

//定位上下左右居中
.center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//定位上下居中
.ct {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

//定位左右居中
.cl {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

//宽高 
.wh(@width, @height) {
    width: @width;
    height: @height;
}

.whi(@width, @height) {
    width: @width !important;
    height: @height !important;
}

//字体大小、行高、字体
.font(@size, @line-height, @family: 'Microsoft YaHei') {
    font: @size/@line-height @family;
}

//字体大小，颜色
.sc(@size, @color) {
    font-size: @size;
    color: @color;
}

//flex 布局和 子元素 对其方式
.fj(@type: space-between) {
    display: flex;
    justify-content: @type;
}

//表单元素垂直居中对齐（也可设置顶对齐，底对齐）
.form-element-v-align(@alignment: middle) {
    display: inline-block;
    vertical-align: @alignment;
    *vertical-align: auto;
    *display: inline;
    zoom: 1;
}

//水平居中
.horizontal-center() {
    margin-left: auto;
    margin-right: auto;
}

//绝对居中(相对于固定宽高的容器)
.absolute-center(@width, @height) {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -(@width / 2);
    margin-top: -(@height / 2);
    width: @width;
    height: @height;
}

//浮动（主要针对PC端了...）
.float(@side: left) {
    float: @side;
    _display: inline;
}

//清除浮动(其实很多时候，clear-fix也被提取为单类使用)
.clear-fix {
    *zoom: 1;
    
    &:before,
    &:after {
        content: "";
        display: table;
        clear: both;
        overflow: hidden;
    }
}

//截取文本(仅针对单行)
.text-single-line-ellipsis(@height, @line-height, @width: auto) {
    width: @width;
    height: @height;
    line-height: @line-height;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//设置透明度
.opacity(@val) {
    filter: alpha(opacity=@val);
    opacity: @val / 100;
}

//不可用状态
.disabled(@bgColor: #e6e6e6, @textColor: #ababab) {
    background-color: @bgColor !important;
    color: @textColor !important;
    cursor: not-allowed !important;
}

//最小高度（主要针对PC端了...）
.min-height(@height) {
    min-height: @height;
    height: auto !important;
    _height: @height;
}

// 多行字体省略
.ellipsis(@lineNum) {
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: @lineNum;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
}

// 设置页面背景色
.bj(@color) {
    position: absolute;
    width: 100%;
    height: 100%;
    background: @color;
    overflow: hidden;
    overflow-y: auto;
}

.vendor(@prop, @args) {
    -webkit-@{prop}: args;
    -moz-@{prop}: args;
        @{prop}: args;
}


.transition() {
    .vendor('transition', arguments);
}

.transform() {
    .vendor('transform', arguments);
}